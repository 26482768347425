import { styled } from '../stitches.config';

export default styled('hr', {
  width: '100%',
  height: '0px',

  border: 'none',
  borderTop: '1px solid $gray02',
  outLine: 'none',
  margin: '0',
  padding: '0',
});
