import { css, ImageMemo, Typography } from '@zaritalk/ui-components/index';
import Layout from '@zaritalk/ui-components/Layout';

export interface ExplainItemProps {
  title: string;
  titleEmojiSrc: string;
  titleEmojiAlt: string;
  contents: string;
  size?: number;
  marginLeft?: number;
}

export default function ExplainItem({
  title,
  titleEmojiSrc,
  titleEmojiAlt,
  contents,
  size = 26,
  marginLeft = 8,
}: ExplainItemProps) {
  return (
    <Layout.ColCenter css={{ marginTop: '60px' }}>
      <Layout.RowCenter>
        <Typography variant={'20_BOLD_MIDDLE'} css={{ textAlign: 'center', color: '$black' }}>
          {title}
        </Typography>
        <ImageMemo
          height={`${size}px`}
          width={`${size}px`}
          className={css({ marginLeft: `${marginLeft}px` })()}
          src={titleEmojiSrc}
          alt={titleEmojiAlt}
        />
      </Layout.RowCenter>
      <Typography
        variant={'14_REGULAR_WIDE'}
        css={{
          textAlign: 'center',
          color: '$black',
          marginTop: '8px',
          whiteSpace: 'pre-wrap',
          wordBreak: 'keep-all',
        }}
      >
        {contents}
      </Typography>
    </Layout.ColCenter>
  );
}
