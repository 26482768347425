import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import { MAIN_PAGE, ZARITALK_TOKEN } from '@zaritalk/constants';
import { LessorMainIntroExplain } from '@zaritalk/domain/auth';
import withBaseHead from '@zaritalk/lessor/hoc/withBaseHead';
import { isZaritalkApp } from '@zaritalk/protocol';
import { HR, Layout, Skeleton } from '@zaritalk/ui-components/index';
import { serverSideErrorHandler } from '@zaritalk/utils/lib/errorHandler';

const LessorMainIntroLogin = dynamic(
  async () => {
    const mod = await import('@zaritalk/domain/auth');
    return mod.LessorMainIntroLogin;
  },
  {
    ssr: false,
    loading: () => (
      <>
        <Skeleton style={{ height: '48px', width: '250px', borderRadius: '33px' }} />
        <Skeleton style={{ marginTop: '10px', height: '48px', width: '250px', borderRadius: '33px' }} />
      </>
    ),
  },
);

const LessorMainIntroFooter = dynamic(
  async () => {
    const mod = await import('@zaritalk/domain/auth');
    return mod.LessorMainIntroFooter;
  },
  { ssr: false },
);

const MemberTypeSelectPopup = dynamic(
  async () => {
    const mod = await import('@zaritalk/domain/auth');
    return mod.MemberTypeSelectPopup;
  },
  { ssr: false },
);

const LessorMainIntroHeader = dynamic(
  async () => {
    const mod = await import('@zaritalk/domain/auth');
    return mod.LessorMainIntroHeader;
  },
  {
    ssr: false,
    loading: () => (
      <Layout.ColCenter css={{ padding: '32px 0px 24px', maxWidth: '$MAX_MOBILE_WIDTH' }}>
        <Skeleton style={{ height: '35px', width: '250px', marginBottom: '12px', borderRadius: '33px' }} />
        <Skeleton style={{ height: '44px', width: '250px', borderRadius: '33px' }} />
      </Layout.ColCenter>
    ),
  },
);

const LessorMainIntroNav = dynamic(
  async () => {
    const mod = await import('@zaritalk/domain/auth');
    return mod.LessorMainIntroNav;
  },
  {
    ssr: false,
    loading: () => (
      <Layout.RowBetweenCenter css={{ padding: '18px 12px', maxWidth: '$MAX_MOBILE_WIDTH' }}>
        <Skeleton style={{ height: '33px', width: '120px', borderRadius: '33px' }} />
        <Skeleton style={{ height: '33px', width: '169px', borderRadius: '33px' }} />
      </Layout.RowBetweenCenter>
    ),
  },
);

export default withBaseHead(function _LessorMainIntroPage() {
  return (
    <Layout.ColCenter css={{ backgroundColor: '$white' }}>
      <Head>
        <link rel={'shortcut icon'} href={'https://static.zaritalk.com/favicon.ico'} />
        <link rel={'canonical'} href={'https://zaritalk.com/'} />
      </Head>

      <LessorMainIntroNav />
      <LessorMainIntroHeader />
      <LessorMainIntroLogin />

      <HR css={{ marginTop: '64px' }} />

      <LessorMainIntroExplain />

      <HR />

      <LessorMainIntroFooter />
      <MemberTypeSelectPopup />
    </Layout.ColCenter>
  );
});

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const cookies = ctx.req.cookies;
  const authToken = cookies[ZARITALK_TOKEN];
  const isApp = isZaritalkApp(ctx);

  try {
    if (authToken) {
      return {
        redirect: {
          destination: MAIN_PAGE,
          permanent: false,
        },
      };
    }

    if (isApp) {
      return {
        redirect: {
          destination: '/intro/app',
          permanent: false,
        },
      };
    }

    ctx.res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');

    return {
      props: {},
    };
  } catch (error) {
    return serverSideErrorHandler(error, ctx);
  }
};
