import { keyframes } from '@stitches/react';
import { CSSProperties } from 'react';

import { styled } from '../../stitches.config';

const skeletonKeyframe = keyframes({
  '0%': {
    opacity: 0.6,
  },
  '50%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0.6,
  },
});

const SkeletonW = styled('span', {
  position: 'relative',
  width: '100%',

  backgroundColor: '#eee',
  borderRadius: '15px',
  display: 'inline-block',
  lineHeight: 1,

  isolation: 'isolate',
  overflow: 'hidden',
  mixBlendMode: 'multiply',
  animation: `${skeletonKeyframe} 1.2s ease-in-out infinite`,

  willChange: 'opacity',
});

type SkeletonProps = {
  count?: number;

  width?: string | number;
  height?: string | number;

  circle?: boolean;
  style?: CSSProperties;
  className?: string;
};

export default function Skeleton({
  count = 1,
  width = '100%',
  height = '30px',
  circle = false,
  className = '',
  style,
}: SkeletonProps): JSX.Element {
  const elements: Array<JSX.Element> = [];

  for (let i = 0; i < count; i++) {
    const initStyle = {};

    if (width != -1) {
      initStyle['width'] = width;
    }

    if (height != null) {
      initStyle['height'] = height;
    }

    if (width !== -1 && height !== -1 && circle) {
      initStyle['borderRadius'] = '50%';
    }

    elements.push(<SkeletonW key={i} className={className} style={{ ...initStyle, ...style }} />);
  }

  return <>{elements.map((element) => element)}</>;
}
