import { memo } from 'react';

import { EMOJI, NUMBER_OF_LESSOR_USERS_KOREAN_VERSION } from '@zaritalk/constants';
import { Layout } from '@zaritalk/ui-components/index';

import ExplainItem, { ExplainItemProps } from '../ExplainItem';

const LESSOR_EXPLAIN_LIST: ExplainItemProps[] = [
  {
    title: '연체없는 자동 수납관리',
    titleEmojiSrc: EMOJI.DEPOSIT,
    titleEmojiAlt: '자리톡 체크박스 이모지',
    contents: '수납 체크를 잊지 않도록 매달 알림을 드리고\n모바일 임대장부를 자동으로 작성합니다.',
  },
  {
    title: `${NUMBER_OF_LESSOR_USERS_KOREAN_VERSION} 임대인 비밀 커뮤니티`,
    titleEmojiSrc: EMOJI.SECRET_FACE,
    titleEmojiAlt: '자리톡 쉿하는 얼굴 이모지',
    contents: '우리 지역 임대 고수들이 나누는 고급정보를\n지금 바로 확인해보세요. (임대인 핫이슈 알림)',
    marginLeft: 6,
  },
  {
    title: '카톡 고지서 무료발송',
    titleEmojiSrc: EMOJI.KAKAOTALK_YELLOW,
    titleEmojiAlt: '자리톡 카카오톡 이모지',
    contents: '고지서 발송으로 미납 90% 감소! 자리톡이\n대신 발송해 부담이 없습니다. (선택 발송 가능)',
    size: 28,
  },
  {
    title: '부동산 실거래가 알림',
    titleEmojiSrc: EMOJI.GRAPH,
    titleEmojiAlt: '자리톡 그래프 이모지',
    contents: '가장 빠른 부동산 실거래가 알림으로\n관심 매물이 얼마에 팔렸는지 확인하세요.',
    size: 26,
  },
  {
    title: '초스피드 공실 해결',
    titleEmojiSrc: EMOJI.HOUSE,
    titleEmojiAlt: '자리톡 집 이모지',
    contents: '이제 발품팔지 마세요! 근처 부동산 최대 20곳에\n한번에 발송해드립니다. (평균 2주일 내 계약)',
    size: 27,
  },
];

export default memo(function _LessorMainIntroExplain() {
  return (
    <Layout.ColCenter css={{ maxWidth: '$MAX_MOBILE_WIDTH', padding: '5px 24px 80px' }}>
      {LESSOR_EXPLAIN_LIST.map((explainItem) => (
        <ExplainItem key={explainItem.title} {...explainItem} />
      ))}
    </Layout.ColCenter>
  );
});
